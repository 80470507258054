










































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Dossier } from "@/api/models/dossiers/dossier";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import {
  BForm,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
  BFormFile,
  BSpinner,
  BModal,
  BButton,
  BFormInput,
  BInputGroup,
  BTab,
  BCardText,
  VBTooltip,
  BTabs,
  BFormTextarea
} from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { required, min, max, regex } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { AssetsEnum } from "@/api/models/utils/assetsEnum";
import { TauxIPP } from "@/api/models/dossiers/tauxIPP";
import { CategorieInvalidite } from "@/api/models/dossiers/categorieInvalidite";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { Prescripteur } from "@/api/models/options/prescripteurs/prescripteur";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import Cleave from 'vue-cleave-component'
import { Risque } from "@/api/models/dossiers/risques";
import { FamilleDeRisque } from "@/api/models/dossiers/famillesDeRisque";
import { ref } from "@vue/composition-api";
import { TenantPreferenceEnum } from "@/api/models/enums/tenantPreferencesEnum";

import { EnumTenants } from "@/api/models/enums/enumTenants";
import appSettings from "@/appSettings";
@Component({
  components: {
    RessifnetDateInput,
    BForm,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    BFormFile,
    BSpinner,
    BModal,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    BButton,
    BFormInput,
    BInputGroup,
    BTab,
    BTabs,
    BCardText,
    BFormTextarea,
    Cleave
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
})
export default class TabInformationsGenerales extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;

  oppositionPartageFile: File | null = null
  fileName = "";

  droitsEnum = DroitsEnum

  required = ref(required);

  regex = regex;
  
  min = min;
  max = max;

  loading = false;
  refreshIndex = 0;

  rqthConsigne = false;
  sstConsigne = false;
  amenagementConsigne = false;
  consigneTransmissionsDate : any = '';

  ssnMask = {
    blocks: [1, 2, 2, 2, 3, 3, 2]
  }


  @Prop({ required: true }) dossier!: Dossier;
  isInforme: boolean = false;
  sendEmailLoading: boolean = false;
  uploadAccuseLoading: boolean = false;
  consignStatut: boolean = false;
  includeNotes: boolean = false;
  listTauxIPP: PaginatedList<TauxIPP> = new PaginatedList<TauxIPP>();
  listCategorieInvalidite: PaginatedList<CategorieInvalidite> =
    new PaginatedList<CategorieInvalidite>();

  accuseReceptionFichier: File | null = null

  options: any = [
    { text: "Masculin", value: "true" },
    { text: "Féminin", value: "false" },
    { text: "Non connu/Autres", value: null },
  ];

  searchPrescripteursTotalCount = 0;
  searchPrescripteursItems: Prescripteur[] = [];

  sousDomainesInitiaux = {}

  get tenantPreferences() {
    return this.$store.state.user.tenant_preferences
  }

  get preference() {
    return this.tenantId == EnumTenants.ACTIS;
  }

  get canEdit() {
    return this.$can(this.droitsEnum.DOSSIER_INFO_GENERAL)
  }

  get listTauxIPPs(): TauxIPP[] {
    return this.listTauxIPP.items;
  }

  get listCategorieInvalidites(): CategorieInvalidite[] {
    return this.listCategorieInvalidite.items;
  }

  get getDissalowButton(){
    return !this.dossier.droitsStatuts!.consigneEcheanceRQTHActivee;
  }

  @Watch('oppositionPartageFile')
  selectOppositionPartageFile(){
    this.fileName = this.oppositionPartageFile!.name
  }

  async created() {
    this.tenantId = appSettings.getTenantId();
    await this.loadListTauxIPPs();
    await this.loadListCategoriesInvalidites();
    // await this.loadInfosCreation();
    // await this.searchPrescripteur({});
    this.isInforme = this.dossier.dateActivationRGPD != null;

  }

  getFormatedConsigneDate(){
    var dateString = this.dossier.droitsStatuts!.dateEcheanceRQTH!;
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
     date.setMonth(date.getMonth() - 6);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }

  toggleModal(){    
    this.consigneTransmissionsDate = this.dossier.droitsStatuts?.dateConsigneEcheanceRQTH ? this.dossier.droitsStatuts?.dateConsigneEcheanceRQTH : this.getFormatedConsigneDate();
    if(!this.dossier.droitsStatuts?.consigneEcheanceRQTHActivee){
          this.consignStatut = true;
    }
    this.$bvModal.show('modal-consignes');
  }

  updateConsigneStatut(){
    this.dossier.droitsStatuts!.consigneEcheanceRQTHActivee = !this.consignStatut;    
    if(this.getFormatedConsigneDate() != this.consigneTransmissionsDate){
      this.dossier.droitsStatuts!.dateConsigneEcheanceRQTH = this.consigneTransmissionsDate;
    }
    if(!this.dossier.droitsStatuts?.consigneEcheanceRQTHActivee && this.dossier.droitsStatuts?.dateConsigneEcheanceRQTH){
      this.dossier.droitsStatuts.dateConsigneEcheanceRQTH = undefined;
    }
  }

  @Watch('dossier.droitsStatuts.dateEcheanceRQTH')
    setConsignOn(){
      if(this.dossier.droitsStatuts!.dateEcheanceRQTH && this.dossier.droitsStatuts!.dateEcheanceRQTH != null){
        this.dossier.droitsStatuts!.consigneEcheanceRQTHActivee = true;
      }else {
        this.dossier.droitsStatuts!.consigneEcheanceRQTHActivee = false;
      }
  }

  refreshindexView(event :any){
    this.refreshIndex++;
  }
  async activateRGPDChange(event: any) {
    if (event) {
      await this.activateRGPD();
    }
  }
  toggleConsigne(type:string){
    switch (type) {
      case 'RQTH':
        this.rqthConsigne = !this.rqthConsigne;
        break;
    
      default:
        break;
    }
  }

  modalAccuseReceptionHide() {
    this.dossier.isAccuse = false
  }

  async silentUpdate(){
    (this.$refs['formValidation'] as any)
      .validate()
      .then(async (result: boolean) => {
        if (!result) {
          this.$swal({
            title: 'Problème dans la saisie',
            text: 'Le formulaire n\'est pas complet',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
            buttonsStyling: false,
          })
        }
        else {
          this.dossier.droitsStatuts = this.getDroitsStatutsFormated();
          await this.$http.ressifnet.dossiers
            .put(this.$route.params.id, this.dossier)
            .then(
              async (response: any) => {
               return this.dossier;
              },
              (error: any) => {
                if (error?.response?.data?.errors) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  errorAlert.fire({
                    text: error.message,
                  });
                }
              }
            );
        }
      })
  }

  async updateDossier() {
    (this.$refs['formValidation'] as any)
      .validate()
      .then(async (result: boolean) => {
        if (!result) {
          this.$swal({
            title: 'Problème dans la saisie',
            text: 'Le formulaire n\'est pas complet',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
            buttonsStyling: false,
          })
        }
        else {
          this.dossier.droitsStatuts = this.getDroitsStatutsFormated();
          await this.$http.ressifnet.dossiers
            .put(this.$route.params.id, this.dossier)
            .then(
              async (response: any) => {
                this.$emit('reloadDossier')
                successAlert.fire({
                  title: "Modification d'un dossier",
                  text: "Modification effectuée avec succès",
                });
              },
              (error: any) => {
                if (error?.response?.data?.errors) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  errorAlert.fire({
                    text: error.message,
                  });
                }
              }
            );
        }
      })
  }

  async loadListTauxIPPs() {
    await this.$http.ressifnet.tauxIPPs.paginatedList(1, 50, true).then(
      (response: PaginatedList<TauxIPP>) => {
        this.listTauxIPP = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des taux IPP",
        });
      }
    );
  }

  async loadListCategoriesInvalidites() {
    await this.$http.ressifnet.categoriesInvalidites
      .paginatedList(1, 50, true)
      .then(
        (response: PaginatedList<CategorieInvalidite>) => {
          this.listCategorieInvalidite = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des catégories d'invalidite",
          });
        }
      );
  }

  async sendEmailRGPD() {
    this.sendEmailLoading = true;
    await this.$http.ressifnet.dossiers
      .sendEmailRGPD(this.$route.params.id)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Envoi de l'email RGPD",
            text: "Email envoyé",
          });
          this.isInforme = true;
          this.sendEmailLoading = false;
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
          this.sendEmailLoading = false;
        }
      );
  }

  async activateRGPD() {
    this.$bvModal
      .msgBoxConfirm('Êtes-vous sûr de vouloir indiquer que le salarié a été informé ?', {
        title: 'Confirmation',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.$http.ressifnet.dossiers
            .activateRGPD(this.$route.params.id)
            .then(
              async (response: any) => {
                this.$emit('reloadDossier')
                successAlert.fire({
                  title: "Le salarié est informé",
                  text: "Vous avez indiqué avoir informé le salarié",
                });
                this.isInforme = true;
              },
              (error: any) => {
                //TODO : quelle erreur ?
                if (error?.response?.data?.errors) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  errorAlert.fire({
                    text: error.message,
                  });
                }
              }
            );
        }
        else {
          this.isInforme = false;
          return;
        }
      })

  }

  async toggleDossierPrive() {
    this.$bvModal
      .msgBoxConfirm(`Êtes-vous sûr de vouloir rendre ce dossier ${this.dossier.dossierPrive ? 'public' : 'privé'} ?`, {
        title: 'Confirmation',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.$http.ressifnet.dossiers
            .dossierPrive(this.$route.params.id)
            .then(
              async (response: any) => {
                this.$emit('reloadDossier')
                successAlert.fire({
                  title: this.dossier.dossierPrive ? "Le dossier est public" : "Le dossier est privé",
                  text: this.dossier.dossierPrive ? "Vous avez rendu le dossier public" : "Vous avez rendu le dossier privé",
                });
              },
              (error: any) => {
                if (
                  error?.response?.data?.errors &&
                  Object.keys(error.response.data.errors).length
                ) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  errorAlert.fire({
                    text: error?.response?.data?.detail ?? error.message,
                  });
                }
              },
            );
        }
        else {
          return;
        }
      })
  }

  async uploadOppositionPartageFile() {
    if (this.oppositionPartageFile) {
      const modifiedFile = new File(
        [this.oppositionPartageFile],
        this.fileName,
        { type: this.oppositionPartageFile.type }
      );

      await this.$http.ressifnet.dossiers
      .uploadOppositionPartageDonnees(this.$route.params.id, modifiedFile, this.dossier.oppositionPartage)
      .then(
        async (response: any) => {
          this.$emit('reloadDossier')
          successAlert.fire({
            title: "Envoi de l'opposition de partage de données",
            text: "Envoi effectué avec succès",
          });
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
    }
  }

  async downloadFile() {
    await this.$http.ressifnet.fichiersDossiers
      .downloadFile(this.dossier.oppositionPartageFileId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async downloadModeleOpposition() {
    await this.$http.ressifnet.utils
      .downloadAsset(AssetsEnum.ACCORD_OPPOSITION_PARTAGE)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async downloadRGPDAttest() {
    await this.$http.ressifnet.utils
      .downloadAsset(AssetsEnum.ATTESTATION_RGPD)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async extractDataRGPD(includeNote: boolean) {
    this.loading = true;
    console.log(`Exporting dossier {${this.dossier.id}} to PDF`);
    await this.$http.ressifnet.dossiers
      .extractToPdf(this.$route.params.id, includeNote)
      .then(
        async (response: any) => {
          console.log(response);
          var blob = new Blob([response], { type: 'application/pdf;' });
          console.log(blob)
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          var date = new Date();
          link.setAttribute('download', `extracted_dossier_${this.dossier.nom}_${this.dossier.prenom}_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
    this.loading = false;
  }

  accuseReceptionChange(event: any) {
    if (event) {
      this.$bvModal.show("modal-upload-accuse-reception");
    }
  }

  async uploadAccuseReception(event: any) {
    this.uploadAccuseLoading = true
    event.preventDefault()
    await this.$http.ressifnet.dossiers
      .uploadAccuseReception(this.$route.params.id, this.accuseReceptionFichier)
      .then(
        async (response: any) => {
          this.$bvModal.hide("modal-upload-accuse-reception");
          this.uploadAccuseLoading = false
          this.dossier.isAccuse = true
          this.$emit('reloadDossier')
          successAlert.fire({
            title: "Envoi de l'accusé de réception",
            text: "Envoi effectué avec succès",
          });
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  getDroitsStatutsFormated() {
    
    if (this.dossier.droitsStatuts) {
      if (this.dossier.droitsStatuts.recoRQTH == false) {
        this.dossier.droitsStatuts.dateRQTH = undefined;
        this.dossier.droitsStatuts.dateEcheanceRQTH = undefined;
        this.dossier.droitsStatuts.employeNonInforme = undefined;
      }
      if (this.dossier.droitsStatuts.recoInvalidite == false) {
        this.dossier.droitsStatuts.dateAttributionInvalidite = undefined;
        this.dossier.droitsStatuts.categorieInvaliditeId = null;
      }
      if (this.dossier.droitsStatuts.recoIPP == false) {
        this.dossier.droitsStatuts.tauxIPPId = null;
      }

      if(this.dossier.droitsStatuts.dateEcheanceRQTH && this.dossier.droitsStatuts.consigneEcheanceRQTHActivee){
        this.dossier.droitsStatuts.consigneEcheanceRQTHActivee = true;
      }            

      return this.dossier.droitsStatuts;
    }
    return {}
  }

  async defaultDate() {
    this.dossier = {
      ...this.dossier,
      dateNaissance: new Date("01/01/1900").toLocaleString()
    }    
    this.$bvModal.hide('dateValidationModale');
  }
}
