






























import VueApexCharts from "vue-apexcharts";
import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
    VueApexCharts
  },
  name: "bar-niveau-stress-consultations-mdt-chart",
})
export default class BarNiveauStressConsultationsMdtChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement individuel - Visites sous délégation MDT";
  description = "(038) Répartition par niveau de stress des consultations sous délégation MDT";

  tableFields = [
    { key: "niveauStress", label: "Type de visite", type: "text", editable: true },
    { key: "nombrePI", label: "Nombre", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
  {
    id: 1,
    niveauStress: "1",
    nombrePI: 10,
  },
  {
    id: 2,
    niveauStress: "2",
    nombrePI: 20,
  },
  {
    id: 3,
    niveauStress: "3",
    nombrePI: 34,
  },
  {
    id: 4,
    niveauStress: "4",
    nombrePI: 20,
  },
  {
    id: 5,
    niveauStress: "5",
    nombrePI: 60,
  },
  {
    id: 6,
    niveauStress: "6",
    nombrePI: 12,
  },
  {
    id: 7,
    niveauStress: "7",
    nombrePI: 48,
  },
  {
    id: 8,
    niveauStress: "8",
    nombrePI: 36,
  },
  {
    id: 9,
    niveauStress: "9",
    nombrePI: 18,
  },
  {
    id: 10,
    niveauStress: "10",
    nombrePI: 25,
  },

]

  addLinePayload = {
    niveauStress: "Autres",
    nombrePI: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombrePI))
      let seriesArray = this.sampleDatas.map((x: any) => x.niveauStress)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  onRowAdded(payload: any) {

    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {

      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombrePI))
      let seriesArray = this.sampleDatas.map((x: any) => x.niveauStress)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },        
      ];
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  async loadData(graphRef: any) {
    await this.$http.ressifnet.rapportActivites
      .repartitionNiveauStressConsultationMDT(this.model)
      .then((response: any) => {
        let dataArray = response.map((x: any) => parseInt(x.nombrePI))
        let seriesArray = response.map((x: any) => x.niveauStress)

        this.seriesArr = [
          {
            key: "Nombre",
            name: "Nombre",
            data: dataArray
          }
        ];

        this.categoriesArr = seriesArray as any[]
        this.sampleDatas = response as any[];

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, true, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData= false;

        let message = e.response?.data?.errors ? 
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any) {
    await this.loadData(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  
  baseConfig = {
  series: [
    {
      key: "Pourcentage",
      name: "Pourcentage",
      data: [10, 20, 30],
    }
  ],
  chartOptions: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        isFunnel: false
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: 0,
      style: {
        fontSize: '14px'
      },
      formatter: function (val: any, opts: any) {
        const index = opts.dataPointIndex;
        let niveauStress = opts.w.config.xaxis.categories[index];

        if (Array.isArray(niveauStress)) {
          niveauStress = niveauStress.join(" ");
        }

        const nombre = val;
        const total = opts.w.config.series[0].data.reduce(
          (acc: number, curr: number) => acc + curr,
          0
        );
        const percentage = ((val / total) * 100).toFixed(0);

        // only if funnel or multiline
        // return [[`${niveauStress}`], [`${percentage}% (${nombre})`]];
        return [[`${percentage}%`]];
      },
    },
    legend: {
      show: false,
      position: 'bottom',
      fontSize: '18px',
      width: 550,
      offsetX: 50,
      formatter: function (val: any, opts: any) {
        let selected = opts.w.config.series[opts.seriesIndex];
        return opts.w.config.labels[opts.seriesIndex] + " (" + selected + ")";
      },
    },
    stroke: {
      show: true,
      colors: ['transparent'],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: this.sampleDatas.map((x: any) => x.niveauStress),
      labels: {
        style: {
          fontSize: '20px'
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        trim: false,
        offsetY: 15,
        style: {
          fontSize: '18px'
        }
      }
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val: any, opts: any) => {
          return val;
        }
      }
    },
  }
}
}
