import api from "@/libs/axios";
import {
  Dossier,
  DossierRapideCreateModel,
  DossiersReferentCount,
  TransfertDossierProfilSearchParams,
  TransfertDossiersSecteurSearchParams,
} from "../models/dossiers/dossier";
import { PaginatedList } from "../models/common/paginatedList";
import {
  DossierCreateModel,
  DossierSearchParams,
  DossierSearchExistingParams,
} from "../models/dossiers/dossier";
import { UserDetail } from "../models/authorization/userDetails/userDetail";
import { Fichier } from "../models/dossiers/fichier";
import { EtudeDePoste } from "../models/dossiers/etudeDePoste";

const resource = "Dossiers";

export default class DossiersRessource {
  public async paginatedListReferents(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<UserDetail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(`${resource}/referentsSelectionnables/`, {
      params: params,
    });
    return response.data;
  }

  public async paginatedListPrescripteurs(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<UserDetail>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search) params.append("Search", search.toString());

    const response = await api.get(
      `${resource}/prescripteursSelectionnables/`,
      { params: params }
    );
    return response.data;
  }

  public async get(id: string): Promise<Dossier> {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  }

  public async getStats(id: string): Promise<any> {
    const response = await api.get(`${resource}/${id}/stats`);
    return response.data;
  }

  public async getLasts(): Promise<PaginatedList<Dossier>> {
    const response = await api.get(`${resource}/lasts`);
    return response.data;
  }

  public async getWaitingRGPD(): Promise<PaginatedList<Dossier>> {
    const response = await api.get(`${resource}/waitingRGPD`);
    return response.data;
  }

  public async search(
    data: DossierSearchParams
  ): Promise<PaginatedList<Dossier>> {
    const response = await api.post(`${resource}/search`, data);
    return response.data;
  }

  public async export(data: DossierSearchParams): Promise<any> {
    const response = await api.post(`${resource}/export`,  data, {
      responseType: "arraybuffer",
      timeout: 120000,
    });
    return response.data;
  }

  public async post(data: DossierCreateModel): Promise<DossierCreateModel> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async postRapide(data: DossierRapideCreateModel): Promise<DossierRapideCreateModel> {
    const response = await api.post(`${resource}/rapide`, data);
    return response.data;
  }

  public async put(id: string, data: Dossier): Promise<Dossier> {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async delete(id: string) {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async restore(id: string) {
    const response = await api.put(`${resource}/${id}/restore`);
    return response.data;
  }

  public async updateReferent(
    id: string,
    referentId: string
  ): Promise<Dossier> {
    const response = await api.put(`${resource}/${id}/referent`, {
      id: id,
      referentId: referentId,
    });
    return response.data;
  }

  public async sendEmailRGPD(id: string): Promise<Dossier> {
    var data = {
      dossierId: id,
    };
    const response = await api.post(`${resource}/${id}/SendRGPDEmail`, data);
    return response.data;
  }

  public async activateRGPD(id: string): Promise<Dossier> {
    var data = {
      dossierId: id,
    };
    const response = await api.post(`${resource}/${id}/ActivateRGPD`, data);
    return response.data;
  }

  public async confirmRGPD(id: string, timestamp: string): Promise<Dossier> {
    const response = await api.post(
      `${resource}/ConfirmRGPD/${id}/${timestamp}`
    );
    return response.data;
  }

  public async extractToPdf(id: string, includeNotes: boolean) : Promise<Dossier> {
    let formData = new FormData();
    formData.append('IncludeNotes', includeNotes.toString());

    const response = await api.post(`${resource}/${id}/ExtractToPdf`, formData,  {
      responseType: "arraybuffer",
      timeout: 120000,
    });
    return response.data
  }

  public async uploadAccuseReception(id: string, file: any) : Promise<Dossier> {
    let formData = new FormData();
    formData.append("Fichier", file);

    const response = await api.post(
      `${resource}/${id}/AccuseReception`,
      formData
    );
    return response.data;
  }

  public async uploadOppositionPartageDonnees(id: string, file: any, oppositionPartage: boolean) : Promise<Dossier> {
    let formData = new FormData();
    formData.append("Fichier", file);
    formData.append('OppositionPartage', oppositionPartage.toString());

    const response = await api.post(
      `${resource}/${id}/OppositionPartageDonnees`,
      formData
    );
    return response.data;
  }

  public async dossierPrive(id: string): Promise<Dossier> {
    var data = {
      dossierId: id,
    };
    const response = await api.post(`${resource}/${id}/DossierPrive`, data);
    return response.data;
  }

  public async importDossiers(
    secteurId: number,
    file: Blob,
    preview: boolean,
    config: any
  ): Promise<any> {
    let formData = new FormData();

    formData.append("secteurId", secteurId.toString());
    formData.append("fichier", file);

    const response = await api.post(
      `${resource}/import`,
      formData,
      config
    );

    return response.data;
  }

  public async previewDossiers(
    secteurId: number,
    file: Blob,
    pageNumber: number,
    perPage: number,
    config: any
  ): Promise<any> {
    let formData = new FormData();

    formData.append("SecteurId", secteurId.toString());
    formData.append("Fichier", file);
    formData.append("PageNumber", pageNumber.toString());
    formData.append("PageSize", perPage.toString());


    const response = await api.post(
      `${resource}/previewDossiersExcel`,
      formData,
      config
    );

    return response.data;
  }

  public async uploadFichier(
    id: string,
    files: Fichier[],
    config: any
  ): Promise<any> {
    let formData = new FormData();
    files.forEach((file: any) => formData.append("fichiers", file));

    const response = await api.post(
      `${resource}/${id}/Fichiers`,
      formData,
      config
    );
    return response.data;
  }

  public async putReferent(
    id: string,
    data: TransfertDossierProfilSearchParams
  ): Promise<any> {
    const response = await api.put(`${resource}/referents/${id}`, data);
    return response.data;
  }

  // public async getDossiersReferentCount(
  //   referentId: string,
  //   data: TransfertDossierProfilSearchParams
  // ): Promise<PaginatedList<DossiersReferentCount>> {
  //   const params = new URLSearchParams();
  //   if (data.brancheId) params.append("BrancheId", data.brancheId.toString());
  //   if (data.entrepriseId)
  //     params.append("EntrepriseId", data.entrepriseId.toString());
  //   if (data.newReferentId)
  //     params.append("NewReferentId", data.newReferentId.toString());
  //   if (data.isGlobal) params.append("IsGlobal", data.isGlobal.toString());
  //   if (data.referentId)
  //     params.append("ReferentId", data.referentId.toString());
  //   if (data.regionId) params.append("RegionId", data.regionId.toString());
  //   if (data.secteurId) params.append("SecteurId", data.secteurId.toString());
  //   if (data.siteId) params.append("SiteId", data.siteId.toString());

  //   const response = await api.get(
  //     `${resource}/referents/${data.referentId}/count`,
  //     { params: params }
  //   );
  //   return response.data;
  // }

  public async putSecteur(
    id: string,
    data: TransfertDossiersSecteurSearchParams
  ): Promise<any> {
    const response = await api.put(`${resource}/secteurs/${id}`, data);
    return response.data;
  }

  public async getDossiersSecteurCount(
    secteurId: string
  ): Promise<PaginatedList<DossiersReferentCount>> {
    const params = new URLSearchParams();
    params.append("SecteurId", secteurId.toString());

    const response = await api.get(`${resource}/secteurs/${secteurId}/count`, {
      params: params,
    });
    return response.data;
  }

  public async loadInfosDossier(dossierId: string): Promise<Dossier> {
    const response = await api.get(`${resource}/${dossierId}/infosCreation`);
    return response.data;
  }
  
  public async getEtudeDePosteById(id: string): Promise<EtudeDePoste> {
    const response = await api.get(`${resource}/${id}/EtudesDePoste/${id}`);
    return response.data;
  }


  public async deleteEtudeDePoste(dossierId:string, id : string) : Promise<EtudeDePoste> {
    const response = await api.delete(`${resource}/${dossierId}/EtudesDePoste/${id}`)
    return response.data
  }

  public async dossierEmailsOnReferentTenant() : Promise<any> {
    const response = await api.get(`${resource}/export/emails`)
    return response.data
  }
}
