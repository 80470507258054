import { graphType } from "@/api/models/enums/graphType";

type graphItem = {
  ratioBottom?: number;
  ratioTop?: number;
  ratioLeft?: number;
  ratioRight?: number;
  legendWidth?: number;
  legendHeight?: number;
  ref: string;
  name: string;
  customSize?: boolean;
  typeNumber?: boolean;
  allowMaxCategory?: boolean;

  legendWidthWhenTopOrBottom?: number
  legendOffsetWhenTopOrBottom?: number

  graphType?: graphType;
  legendFixed?: boolean;
}
const arr: graphItem[] = [
  {
    name: "001 - (Nombre) Dossiers actifs",
    ref: "nombreDossiersActifs",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "002 - (Nombre) Dossiers actifs avec au moins une consultation IST",
    ref: "nombreDossiersActifsCONSIST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "003 - (Nombre) Nombre de dossiers actifs avec au moins une visite sous délégation MDT",
    ref: "nombreDossiersActifsMDT",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "004 - (Nombre) Nombre de dossiers salariés ayant une RQTH sur l'ensemble des dossiers actifs existants",
    ref: "nombreDossiersActifsRQTH",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "005 - (Nombre) Nombre de dossiers salariés ayant une invalidité sur l'ensemble des dossiers actifs existants",
    ref: "nombreDossiersActifsInvalidite",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "006 - (Nombre) Nombre de dossiers salariés ayant une MP sur l'ensemble des dossiers actifs existants",
    ref: "nombreDossiersActifsMP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "007 - (Histogramme) Répartition des dossiers par codes de MP",
    ref: "bar_dossiers_by_maladie_pro_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "008 - (Nombre) Nombre de dossiers salariés avec au moins une étude de poste'",
    ref: "nombreDossiersActifsEDP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "009 - (Nombre) Nombre d'études de poste réalisées",
    ref: "nombreEdp",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "010 - (Nombre) Temps total passé sur les EDP de l'année",
    ref: "nombreTpsTotalEDP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "011 - (Histogramme) Répartition du nombre d'étude de poste individuelle par contexte d’étude de poste",
    ref: "bar_dossier_by_edp_contexte_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "012 - (Histogramme) Répartition du nombre d'étude de poste individuelle par types de prescripteur",
    ref: "bar_dossier_by_edp_prescripteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "013 - (Histogramme) Répartition du nombre d'étude de poste individuelle par secteur de l'entreprise",
    ref: "bar_edp_dossier_by_secteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "014 - (Nombre) Passages infirmerie pour Consultation IST",
    ref: "nombrePI_Consultation_IST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "015 - (Camembert) Répartition par tranches de temps la durée de travail des consultations IST",
    ref: "duree_travail_consultations_ist_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE
  },
  {
    name: "016 - (Histogramme) Répartition par type de visite des consultations IST",
    ref: "bar_consultations_ist_by_type_visite_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "017 - (Histogramme) Répartition par origine des consultations IST",
    ref: "bar_consultations_ist_by_origine_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "018 - (Histogramme) Répartition par examens complémentaire des consultations IST",
    ref: "bar_consultations_ist_by_examens_complementaire_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "019 - (Histogramme) Répartition par raisons de la venue des consultations IST",
    ref: "bar_consultations_ist_by_raisons_venue_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "020 - (Donut) Répartition du nombre de consultations IST par précisions de la raison venue en Pathologie et soin liés au travail",
    ref: "donut_consultations_ist_by_precisions_raisons_venue_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "021 - (Nombre) Personnes avec Douleurs Psychiques en Consultation IST",
    ref: "nombrePI_Consultation_IST_Douleurs_Psychiques",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "022 - (Nombre) Pourcentage de personnes avec Douleurs Psychiques en Consultation IST",
    ref: "pourcentage_personnes_Consultation_IST_Douleurs_Psychiques",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "023 - (Camembert) Répartition du nombre de consultations IST par type de douleur psychique",
    ref: "pie_consultations_ist_type_douleur_psychique_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE
  },
  {
    name: "024 - (Histogramme) Répartition du nombre de consultations IST par sous catégories (AT / AT bénins / AT mission / Accident Trajet) en Pathologie et soin liés au travail",
    ref: "bar_consultations_ist_by_precisions_raisons_venue_at_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "025 - (Histogramme) Répartition du nombre de consultations IST en Pathologie et soin liés au travail par localisations des douleurs (uniquement sous catégories AT)",
    ref: "bar_consultations_ist_by_localisations_douleurs_only_at_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "026 - (Histogramme) Répartition du nombre de consultations IST en Pathologie et soin liés au travail par localisations des douleurs (uniquement sous catégories MP et TMS)",
    ref: "bar_consultations_ist_by_localisations_douleurs_only_mp_and_tms_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "027 - (Histogramme) Répartition par actions réalisées des consultations IST",
    ref: "bar_consultations_ist_by_actions_realisees_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "028 - (Histogramme) Répartition par orientation des consultations IST",
    ref: "bar_consultations_ist_by_orientations_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "029-1 - (Nombre) Pourcentage du nombre de personnes avec douleurs physiques en visite sous délégation MDT par rapport au nombre de personnes ayant une consultation IST",
    ref: "pourcentage_personnes_visite_ist_douleurs_physiques",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "030-1 - (Histogramme)  Répartition par douleurs physiques du nombre de consultations IST",
    ref: "bar_consultations_ist_by_douleurs_physiques_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "031-1 - (Histogramme) Répartition par jours de la semaine du nombre de consultations IST",
    ref: "bar_consultations_ist_by_jours_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
  },
  {
    name: "032-1 - (Donut) Répartition du nombre de consultations IST par sexe",
    ref: "donut_consultations_ist_by_sexe_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "033-1 - (Donut) Répartition du nombre de consultations IST par types de contrat",
    ref: "donut_consultations_ist_by_types_contrat_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "034-1 - (Histogramme) Répartition par tranches d'âge de la semaine du nombre de consultations IST",
    ref: "bar_consultations_ist_by_tranches_age_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "036-1 - (Donut) Répartition du nombre de consultations IST par catégorie socio pro",
    ref: "donut_consultations_ist_by_csp_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "037-1 - (Donut) Répartition du nombre de consultations IST par temps de travail",
    ref: "donut_consultations_ist_by_temps_travail_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "038-1 - (Donut) Répartition du nombre de consultations IST par horaires",
    ref: "donut_consultations_ist_by_horaires_chart",
    ratioBottom: 509.367 / 650,
    ratioTop: 604.36 / 650,
    ratioRight: 405.69 / 650,
    ratioLeft: 410.69 / 650,
    legendWidth: 200,
    // legendHeight: 75,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.DONUT
  },
  {
    name: "039-1 - (Nombre) Nombre de personnes distinctes ayant eu une consultation IST sur la période",
    ref: "NbPersonnesDistinctesConsultationsIST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "040-1 - (Histogramme) Répartition par mois de l'année du nombre de consultations IST",
    ref: "bar_consultations_ist_by_mois_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
  },



  // PARTIE Individuelle - VISITE MDT
  // 029 = "nombrePITypeVisiteMDT"
  {
    name: "029 - (Nombre) Nombre de passage infirmerie pour consultation sous délégation MDT",
    ref: "nombrePITypeVisiteMDT",
    typeNumber: true,
    graphType : graphType.NUMBER
  },
  // 030 = bar_duree_travail_consultations_mdt_chart
  {
    name: "030 - (Camembert) Répartition par tranches de temps de la durée de travail des consultations sous délégation MDT",
    ref: "bar_duree_travail_consultations_mdt_chart",
    ratioBottom: 367.69/650,
    ratioTop: 367.69/650,
    ratioRight: 402.69/650,
    ratioLeft: 402.69/650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE
  },
  // 031 = bar_type_visite_consultations_mdt_chart 
  {
    name: "031 - (Histogramme) Répartition par types de visite des consultations sous délégation MDT",
    ref: "bar_type_visite_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR
  },
  // 032 = bar_origine_consultations_mdt_chart
  {
    name: "032 - (Histogramme) Répartition par origines de la demande des consultations sous délégation MDT",
    ref: "bar_origine_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR
  },
  // 033 = bar_examen_comp_by_type_examen_chart
  {
    name: "033 - (Histogramme) Parmi toutes les visites sur délégation, répartition du nombre d’examens complémentaires réalisés par types d’examens",
    ref: "bar_examen_comp_by_type_examen_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
    allowMaxCategory: true
  },
  // 034 = bar_raison_venue_consultations_mdt_chart
  {
    name: "034 - (Histogramme) Répartition du nombre de visites sur délégation MDT par raison de la venue",
    ref: "bar_raison_venue_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR
  },
  // #035 - Consultations MDT par Pathologies et Soins liés au Travail
  {
    name: "035 - (Donut) Répartition du nombre de visites sur délégation MDT par pathologies et soins liés au travail",
    ref: "donut_pathologie_soin_travail_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR
  },
  // 036 = nombrePIConsultationMdtDouleursPsychiques
  {
    name: "036 - (Nombre) Nombre de visite(s) sous délégation MDT avec douleur(s) psychique(s)",
    ref: "NombrePIConsultationMdtDouleursPsychiques",
    typeNumber: true,
    graphType : graphType.NUMBER
  },
  // 037 = "pourcentagePersonnesVisiteDelegationMdtDouleursPsychiques"
  {
    name: "037 - (Nombre) Pourcentage du nombre de personnes avec douleurs psychiques en visite sous délégation MDT par rapport au nombre de personnes ayant une consultation IST",
    ref: "pourcentagePersonnesVisiteDelegationMdtDouleursPsychiques",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  // 038 = "bar_niveau_stress_consultations_mdt_chart"
  {
    name: "038 - (Entonnoir) Répartition par niveau de stress des consultations sous délégation MDT",
    ref: "bar_niveau_stress_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  // 039 = "bar_niveau_satisfaction_consultations_mdt_chart"
  {
    name: "039 - (Entonnoir) Répartition par niveau de satisfaction des consultations sous délégation MDT",
    ref: "bar_niveau_satisfaction_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  // #040 - Consultations MDT par Point Positif
  {
    name: "040 - (Histogramme) Répartition du nombre de visites sur délégation MDT par point positif",
    ref: "bar_point_positif_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
    allowMaxCategory: true
  },
  // #041 - Consultations MDT par Point Negatif
  {
    name: "041 - (Histogramme) Répartition du nombre de visites sur délégation MDT par point négatif",
    ref: "bar_point_negatif_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  // #042 - Consultations MDT par Action Realisee
  {
    name: "042 - (Histogramme) Répartition du nombre de visites sur délégation MDT par action réalisée",
    ref: "bar_action_realisee_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  // #043 - Consultations MDT par Orientation
  {
    name: "043 - (Histogramme) Répartition du nombre de visites sur délégation MDT par orientation",
    ref: "bar_orientation_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
    allowMaxCategory: true
  },
  // #044 - Consultations MDT par Douleur Psychique
  {
    name: "044 - (Camembert) Répartition du nombre de visites sur délégation MDT par douleur psychique",
    ref: "pie_douleur_psychique_consultations_mdt_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType : graphType.BAR,
  },	
  // #045 - MDT Douleurs Physique
  {
    name: "045 - A - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cou'",
    ref: "045-A",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - B - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cervicale'",
    ref: "045-B",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - C - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cheville'",
    ref: "045-C",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - D - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Doigt'",
    ref: "045-D",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - E - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Dos'",
    ref: "045-E",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - F - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Jambe'",
    ref: "045-F",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - H - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Main'",
    ref: "045-H",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - I - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Oeil'",
    ref: "045-I",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - J - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Poignets'",
    ref: "045-J",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - K - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Tête'",
    ref: "045-K",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - L - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Thorax'",
    ref: "045-L",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - M - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Abdomen'",
    ref: "045-M",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - N - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Autre'",
    ref: "045-N",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - O - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Pied'",
    ref: "045-O",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - P - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Coude'",
    ref: "045-P",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - Q - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Epaule'",
    ref: "045-Q",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - R - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Genou'",
    ref: "045-R",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "045 - S - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Visage'",
    ref: "045-S",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  // #046 - MDT Douleur Physique / Total MDT
  {
    name: "046 - A - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cou'",
    ref: "046-A",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - B - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cervicale'",
    ref: "046-B",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - C - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Cheville'",
    ref: "046-C",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - D - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Doigt'",
    ref: "046-D",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - E - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Dos'",
    ref: "046-E",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - F - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Jambe'",
    ref: "046-F",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - H - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Main'",
    ref: "046-H",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - I - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Oeil'",
    ref: "046-I",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - J - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Poignets'",
    ref: "046-J",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - K - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Tête'",
    ref: "046-K",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - L - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Thorax'",
    ref: "046-L",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - M - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Abdomen'",
    ref: "046-M",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - N - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Autre'",
    ref: "046-N",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - O - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Pied'",
    ref: "046-O",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - P - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Coude'",
    ref: "046-P",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - Q - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Epaule'",
    ref: "046-Q",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - R - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Genou'",
    ref: "046-R",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "046 - S - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur physique 'Visage'",
    ref: "046-S",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  // #047 - MDT Douleurs Psychique
  {
    name: "047 - A - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Fatigue'",
    ref: "047-A",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "047 - B - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Anxiété'",
    ref: "047-B",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "047 - C - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Troubles du sommeil'",
    ref: "047-C",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "047 - D - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Syndrome dépressif'",
    ref: "047-D",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "047 - E - (Nombre) Nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Autre'",
    ref: "047-E",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  // #048 - MDT Douleur Psychique / Total MDT
  {
    name: "048 - A - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Fatigue'",
    ref: "048-A",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "048 - B - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Anxiété'",
    ref: "048-B",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "048 - C - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Troubles du sommeil'",
    ref: "048-C",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "048 - D - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Syndrome dépressif'",
    ref: "048-D",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "048 - E - (Nombre) Pourcentage du nombre de passages infirmeries en visite sous délégation MDT avec comme douleur psychique 'Autre'",
    ref: "048-E",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "049 - (Nombre) Pourcentage du nombre de personnes avec douleurs physiques en visite sous délégation MDT par rapport au nombre de personnes ayant une visite sous delegation MDT",
    ref: "pourcentage_personnes_visite_mdt_douleurs_physiques",
    typeNumber: true,
    graphType: graphType.NUMBER
  },

  // PARTIE Entreprise - Général (100 a 103)
  {
    name: "100 - (Camembert) Nombre d'action réalisées par domaine",
    ref: "actions_by_domaine_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "101 - (Camembert) Somme du temps passé par domaine",
    ref: "temps_by_domaine_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "102 - (Camembert) Nombre d'action réalisées par sous catégorie AMT",
    ref: "actions_by_sous_categorie_amt_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "103 - (Camembert) Temps passé réalisées par sous catégorie d'AMT",
    ref: "temps_by_sous_categorie_amt_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },


  // PARTIE Entreprise - AMT - Etude de poste (200 a 250)
  {
    name: "201 - (Nombre) Nombre d\'étude de poste réalisées sur la période dans l\'onglet AMT",
    ref: "nombreAMTEDP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "202 - (Histogramme) Répartition du nombre d'étude de poste par \"Contexte de l'étude\"",
    ref: "bar_edp_by_contexte_etude_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "203 - (Histogramme) Répartition du nombre d'étude de poste par \"A la demande de\"",
    ref: "bar_edp_by_prescripteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "204 - (Histogramme) Répartition du nombre d'étude de poste par Région de l'entreprise",
    ref: "bar_edp_by_region_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "205 - (Histogramme) Répartition du nombre d'étude de poste par Site de l'entreprise",
    ref: "bar_edp_by_site_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "206 - (Histogramme) Répartition du nombre d'étude de poste par Secteur de l'entreprise",
    ref: "bar_edp_by_secteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "207 - (Histogramme) Répartition du nombre d'étude de poste par Branche de l'entreprise",
    ref: "bar_edp_by_branche_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "208 - (Histogramme) Répartition des parties prenantes externes sur les EDP",
    ref: "bar_edp_by_parties_prenantes_externes_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },

  



  {
    name: "209 - (Camembert) Répartition des types de poste pour les RPS",
    ref: "pie_amt_edp_type_poste_by_familles_risques_RPS_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques liés au travail sur écran",
    ref: "pie_amt_edp_type_poste_by_familles_risques_travail_ecran_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques liés aux bâtiments ou aux lieux de travail",
    ref: "pie_amt_edp_type_poste_by_familles_risques_batiments_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques liés à l’activité physiques",
    ref: "pie_amt_edp_type_poste_by_familles_risques_physique_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques liés à des situations de travail ou à l’organisation du travail",
    ref: "pie_amt_edp_type_poste_by_familles_risques_travail_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques liés aux phénomènes physiques",
    ref: "pie_amt_edp_type_poste_by_familles_risques_phenomenes_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour risques chimiques",
    ref: "pie_amt_edp_type_poste_by_familles_risques_chimiques_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "209 - (Camembert) Répartition des types de poste pour les risques biologiques",
    ref: "pie_amt_edp_type_poste_by_familles_risques_biologiques_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },



  // PARTIE Entreprise - AMT - Aménagement de poste (250 a 300)
  
  {
    name: "247 - (Nombre) Temps passé (en h) sur les actions 'AMT'",
    ref: "tempspasseamt",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "248 - (Nombre) Temps passé (en h) sur les aménagements de poste",
    ref: "tempspasseamenagementsposte",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "249 - (Nombre) Temps passé (en h) sur les études de poste",
    ref: "tempspasseedpentreprise",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "250 - (Nombre) Nombre d\'aménagements de poste réalisés sur l\'année",
    ref: "nombreAmenagementPoste",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "251 - (Histogramme) Répartition des parties prenantes externes sur l'ensemble des aménagements de poste",
    ref: "bar_amenagement_poste_by_parties_prenantes_externes_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "252 - (Histogramme) Répartition des parties prenantes externes sur l'ensemble des aménagements de poste par site",
    ref: "bar_amenagement_poste_by_parties_prenantes_externes_by_site_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "253 - (Histogramme) Répartition du nombre d'aménagements de poste par site",
    ref: "bar_amenagement_poste_by_site_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "254 - (Histogramme) Répartition des parties prenantes externes sur l'ensemble des aménagements de poste par secteur",
    ref: "bar_amenagement_poste_by_parties_prenantes_externes_by_secteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "255 - (Histogramme) Répartition du nombre d'aménagements de poste par secteur",
    ref: "bar_amenagement_poste_by_secteur_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR
  },
  {
    name: "256 - (Histogramme) Répartition des parties prenantes internes sur l'ensemble des aménagements de poste",
    ref: "bar_amenagement_poste_by_parties_prenantes_internes_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },


  // PARTIE Entreprise - ADM - Formation des SST (300)
  {
    name: "301 - (Nombre) Somme du \"Nombre de salariés impactés\" pour Type Action : « Formation des SST »'",
    ref: "nombreSalariesImpactesTypeAtionSstAmt",
    typeNumber: true,
    graphType: graphType.NUMBER
  },


  // PARTIE Entreprise - ADM (400 a 405)
  {
    name: "401 - (Camembert) Nombre d'action réalisées par type d'action",
    ref: "actions_by_typeaction_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "402 - (Camembert) Somme du temps passé par type d'action",
    ref: "temps_by_typeAction_chart",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE,
    allowMaxCategory: true
  },
  {
    name: "403 - (Histogramme) Répartition des parties prenantes internes des réunions de type \"Point informel \"",
    ref: "bar_parties_prenantes_internes_type_action_point_informel_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "404 - (Histogramme) Répartition des parties prenantes externes des réunions de type \"Point informel \"",
    ref: "bar_parties_prenantes_externes_type_action_point_informel_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },
  {
    name: "405 - (Nombre) Salariés impactés par les actions de type \"Point informel\"",
    ref: "NombreSalariesImpactesPointInformelADM",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "406 - (Nombre) Temps (en vac et hors vac) total passé sur les actions de type \"Suivi des précos\"",
    ref: "nombreTpsTotalActionADMSuiviPreco",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "407 - (Camembert) Nombre de \"suivi des précos\" par secteur de l'entreprise",
    ref: "pie_nombre_actions_adm_suivi_preco_by_secteur",
    ratioBottom: 367.69 / 650,
    ratioTop: 367.69 / 650,
    ratioRight: 402.69 / 650,
    ratioLeft: 402.69 / 650,
    legendWidth: 200,
    legendWidthWhenTopOrBottom: 550,
    legendOffsetWhenTopOrBottom: 50,
    graphType: graphType.PIE
  },
  {
    name: "408 - (Nombre) - Temps (en vac + hors vac) total passé sur les actions de type \"Gestion visites MDT / planification / màj dossiers administratif\" sur la période",
    ref: "NombreTpsTotalActionADMGestionVisiteMdt",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "409 - (Nombre) Temps passé (en h) sur les actions 'ADM'",
    ref: "tempspasseadm",
    typeNumber: true,
    graphType: graphType.NUMBER
  },

  // PARTIE Entreprise - PST (500 a 505)
  {
    name: "501 - (Nombre) - Nombre de projets distincts avec une action sur la période en cours",
    ref: "NombreProjetsPst",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "502 - (Nombre) -  Temps passé au total sur les actions de la période en cours",
    ref: "NombreTpsTotalActionPST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "503 - (Nombre) -  Nombre d'actions sur la période en cours",
    ref: "NombreActionsPST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "504 - (Nombre) -  Temps passé au total sur les projets de la période en cours",
    ref: "NombreTpsTotalProjetsPST",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "505 - (Entonnoir) - Répartition des temps passés par thème de projet (Prévention Santé Travail)",
    ref: "bar_temps_by_theme_projet_pst_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },



  // PARTIE Entreprise - PSP (600 a 605)
  {
    name: "601 - (Nombre) - Nombre de projets distincts avec une action sur la période en cours",
    ref: "NombreProjetsPsp",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "602 - (Nombre) - Temps passé au total sur les actions de la période en cours",
    ref: "NombreTpsTotalActionPSP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "603 - (Nombre) - Nombre d'actions sur la période en cours",
    ref: "NombreActionsPSP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "604 - (Nombre) - Temps passé au total sur les projets de la période en cours",
    ref: "NombreTpsTotalProjetsPSP",
    typeNumber: true,
    graphType: graphType.NUMBER
  },
  {
    name: "605 - (Entonnoir) - Répartition des temps passés par thème de projet (Prévention Santé Publique)",
    ref: "bar_temps_by_theme_projet_psp_chart",
    legendWidth: 220,
    legendHeight: 120,
    customSize: true,
    graphType: graphType.BAR,
    allowMaxCategory: true
  },




  // RESTE A TRIER
  // ???
  // ????
  // {
  //   name: "(Donut) Aptitudes Avis",
  //   ref: "donut_aptitude_avis_chart",
  //   ratioBottom: 509.367 / 650,
  //   ratioTop: 604.36 / 650,
  //   ratioRight: 405.69 / 650,
  //   ratioLeft: 410.69 / 650,
  //   legendWidth: 200,
  //   // legendHeight: 75,
  //   legendWidthWhenTopOrBottom: 550,
  //   legendOffsetWhenTopOrBottom: 50,
  //   graphType: graphType.DONUT,
  //   allowMaxCategory: true
  // },
  // {
  //   name: "(Nombre) Passages infirmerie",
  //   ref: "nombrePI",
  //   typeNumber: true,
  //   graphType: graphType.NUMBER
  // },
  // {
  //   name: "(Camembert) Répartition H/F Dossiers avec Passage Infirmerie",
  //   ref: "dossiers_with_pi_by_sexe_chart",
  //   ratioBottom: 367.69 / 650,
  //   ratioTop: 367.69 / 650,
  //   ratioRight: 402.69 / 650,
  //   ratioLeft: 402.69 / 650,
  //   legendWidth: 200,
  //   legendWidthWhenTopOrBottom: 550,
  //   legendOffsetWhenTopOrBottom: 50,
  //   graphType: graphType.PIE,
  //   allowMaxCategory: true
  // },

]
export default arr;