



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































//#region Imports
import AppCollapse from "@/@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@/@core/components/app-collapse/AppCollapseItem.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { PaginatedList } from "@/api/models/common/paginatedList";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { required } from "@validations";
import { BButton, BToast, BPagination } from "bootstrap-vue";
import { Region } from "@/api/models/options/entreprises/region";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import { BFormCheckbox, BSpinner, BTable } from "bootstrap-vue";
import { Service } from "@/api/models/options/entreprises/service";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";
import {
  GeneratePowerpointModel,
  RapportActiviteDemande,
  RapportActiviteDemandeModel,
} from "@/api/models/rapportActivites/rapportActivite";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import VueApexCharts from "vue-apexcharts";
import { EnumNumberStat } from "@/api/models/enums/enumNumberStat";
import { EnumDouleurPhysiqueStat } from "@/api/models/enums/enumDouleurPhysiqueStat";
import { EnumDouleurPsychiqueStat } from "@/api/models/enums/enumDouleurPsychiqueStat";
import { EtatDemandeRA } from "@/api/models/enums/etatDemandeRA";
import RessifApexLineChart from "./templates/line-charts/RessifApexLineChart.vue";
import RessifApexScatterChart from "./templates/scatter-charts/RessifApexScatterChart.vue";
import DossiersWithPiBySexeChart from "./pie/DossiersWithPIBySexe.vue";
import ActionsByDomaineChart from "./pie/ActionsByDomaineChart.vue";
import TempsByDomaineChart from "./pie/TempsByDomaineChart.vue";
import DonutAptitudeAvisChart from "./donuts/DonutAptitudeAvisChart.vue";
import NombreTemplateRapport from "./nombres/NumberTemplate.vue";
import ActionsBySousCategorieAmtChart from "./pie/ActionsBySousCategorieAmtChart.vue";
import TempsBySousCategorieAmtChart from "./pie/TempsBySousCategorieAmtChart.vue";
import BarEdpByContexteEtudeChart from "./bar/BarEdpByContexteEtudeChart.vue";
import BarEdpByPrescripteurChart from "./bar/BarEdpByPrescripteurChart.vue";
import BarEdpByRegionChart from "./bar/BarEdpByRegionChart.vue";
import BarEdpBySiteChart from "./bar/BarEdpBySiteChart.vue";
import BarEdpBySecteurChart from "./bar/BarEdpBySecteurChart.vue";
import BarEdpByBrancheChart from "./bar/BarEdpByBrancheChart.vue";
import BarAmenagementPosteByPartiesPrenantesExternesChart from "./bar/BarAmenagementPosteByPartiesPrenantesExternesChart.vue";
import BarAmenagementPosteByPartiesPrenantesExternesBySiteChart from "./bar/BarAmenagementPosteByPartiesPrenantesExternesBySiteChart.vue";
import BarAmenagementPosteByPartiesPrenantesExternesBySecteurChart from "./bar/BarAmenagementPosteByPartiesPrenantesExternesBySecteurChart.vue";
import BarAmenagementPosteBySiteChart from "./bar/BarAmenagementPosteBySiteChart.vue";
import BarDossiersByMaladieProChart from "./bar/BarDossiersByMaladieProChart.vue";
import DureeTravailConsultationsIstChart from "./pie/DureeTravailConsultationsIST.vue";
import PieConsultationsIstTypeDouleurPsychiqueChart  from './pie/PieConsultationsISTTypeDouleurPsychique.vue';
import BarAmenagementPosteBySecteurChart from "./bar/BarAmenagementPosteBySecteurChart.vue";
import BarEdpByPartiesPrenantesExternesChart from "./bar/BarEdpByPartiesPrenantesExternes.vue";
import rapportActivitesHub from "@/api/hubs/rapportActivitesHub";
import * as signalR from "@microsoft/signalr";
import {
  RapportActiviteConfig,
  RapportActiviteTemplate,
} from "@/api/models/rapportActivites/rapportActiviteTemplate";
import DisplayIcon from "@/components/icons/DisplayIcon.vue";
import graphList from "@/views/administration/rapports-activites-templates/graphList";
import { graphType } from "@/api/models/enums/graphType";
import { RapportActiviteModel } from "@/api/models/rapportActivites/rapportActivite";
import ActionsByTypeActionChart from "./pie/ActionsByTypesActionChart.vue";
import TempsByTypeActionAdmChart from "./pie/TempsByTypeActionAdmChart.vue";
import BarPartiesPrenantesExternesTypeActionPointInformelChart from "./bar/BarPartiesPrenantesExternesTypeActionPointInformelChart.vue"
import BarPartiesPrenantesInternesTypeActionPointInformelChart from "./bar/BarPartiesPrenantesInternesTypeActionPointInformelChart.vue"
import ActionsAdmSuiviPrecoBySecteurChart from "./pie/ActionsAdmSuiviPrecoBySecteurChart.vue"
import DureeTravailConsultationsMdtChart from "./pie/DureeTravailConsultationsMDTChart.vue";
import BarTypeVisiteConsultationsMdtChart from "./bar/BarTypeVisiteConsultationsMDTChart.vue";
import BarOrigineConsultationsMdtChart from "./bar/BarOrigineConsultationsMdtChart.vue";
import BarExamensCompByTypesExamenChart from "./bar/BarExamensCompByTypesExamenChart.vue";
import BarRaisonVenueConsultationsMdtChart from "./bar/BarRaisonVenueConsultationsMdtChart.vue";
import BarNiveauStressConsultationsMdtChart from "./bar/BarNiveauStressConsultationsMdtChart.vue";
import BarNiveauSatisfactionConsultationsMdtChart from "./bar/BarNiveauSatisfactionConsultationsMdtChart.vue";
import BarConsultationsIstByOrigineChart from './bar/BarConsultationsISTByOrigine.vue';
import BarConsultationsIstByTypeVisiteChart from './bar/BarConsultationsISTByTypeVisite.vue';
import BarConsultationsIstByExamensComplementaireChart from "./bar/BarConsultationsISTByExamensComplementaire.vue";
import BarConsultationsIstByRaisonsVenueChart from "./bar/BarConsultationsISTByRaisonsVenue.vue";
import BarConsultationsIstByOrientationChart from "./bar/BarConsultationsISTByOrientations.vue";
import BarConsultationsIstByActionsRealiseesChart from "./bar/BarConsultationsISTByActionsRealisees.vue";
import BarConsultationsIstByDouleursPhysiquesChart from "./bar/BarConsultationsISTByDouleursPhysiques.vue";
import BarConsultationsIstByJoursChart from './bar/BarConsultationsISTByJours.vue';
import BarConsultationsIstByMoisChart from './bar/BarConsultationsISTByMois.vue';
import BarConsultationsIstByTranchesAgeChart from './bar/BarConsultationsISTByTranchesAge.vue';
import DonutConsultationsIstByPrecisionsRaisonsVenueChart from './donuts/DonutConsultationsISTByPrecisionsRaisonsVenue.vue';
import BarConsultationsIstByPrecisionsRaisonsVenueAtChart from './bar/BarConsultationsISTByPrecisionsRaisonsVenueAT.vue';
import BarConsultationsIstByLocalisationsDouleursOnlyAtChart from './bar/BarConsultationsISTByLocalisationsDouleursOnlyAT.vue';
import BarConsultationsIstByLocalisationsDouleursOnlyMpAndTmsChart from './bar/BarConsultationsISTByLocalisationsDouleursOnlyMPAndTMS.vue';
import DonutConsultationsIstBySexeChart from './donuts/DonutConsultationsISTBySexe.vue';
import DonutConsultationsIstByTypesContratsChart from './donuts/DonutConsultationsISTByTypesContrats.vue';
import DonutConsultationsIstByCspChart from './donuts/DonutConsultationsISTByCSP.vue';
import DonutConsultationsIstByTempsTravailChart from './donuts/DonutConsultationsISTByTempsTravail.vue';
import DonutConsultationsIstByHorairesChart from './donuts/DonutConsultationsISTByHoraires.vue';
import BarDossierByEdpContexteChart from "./bar/BarDossierByEdpContexteChart.vue";
import BarDossierByEdpPrescripteurChart from "./bar/BarDossierByEdpPrescripteurChart.vue";
import BarEdpDossierBySecteurChart from "./bar/BarEdpDossierBySecteurChart.vue";
import BarTempsByThemeProjetPstChart from "./bar/BarTempsByThemeProjetPSTChart.vue";
import BarTempsByThemeProjetPspChart from "./bar/BarTempsByThemeProjetPSPChart.vue";
import BarPointPositifConsultationsMdtChart from "./bar/BarPointPositifConsultationsMDTChart.vue";
import BarPointNegatifConsultationsMdtChart from "./bar/BarPointNegatifConsultationsMDTChart.vue";
import BarActionRealiseeConsultationsMdtChart from "./bar/BarActionRealiseeConsultationsMDTChart.vue";
import BarOrientationConsultationsMdtChart from "./bar/BarOrientationConsultationsMDTChart.vue";
import DouleurPsychiqueConsultationsMdtChart from "./pie/DouleurPsychiqueConsultationsMDTChart.vue";
import DonutConsultationsMdtByPathologiesSoinTravailChart from "./donuts/DonutConsultationsMDTByPathologiesSoinTravailChart.vue";
import PieAmtEdpTypePosteByFamillesRisquesChart from "./pie/PieAmtEdpTypePosteByFamillesRisquesChart.vue";
import BarAmenagementPosteByPartiesPrenantesInternesChart from "./bar/BarAmenagementPosteByPartiesPrenantesInternesChart.vue";

//#endregion
@Component({
  components: {
    BPagination,
    RessifnetDateInput,
    SearchableVueSelect,
    BFormCheckbox,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BButton,
    VueApexCharts,
    RessifApexLineChart,
    BToast,
    NombreTemplateRapport,
    AppCollapse,
    AppCollapseItem,
    DossiersWithPiBySexeChart,
    DureeTravailConsultationsIstChart,
    PieConsultationsIstTypeDouleurPsychiqueChart,
    ActionsByDomaineChart,
    TempsByDomaineChart,
    ActionsBySousCategorieAmtChart,
    TempsBySousCategorieAmtChart,
    BarEdpByContexteEtudeChart,
    BarEdpByPrescripteurChart,
    BarEdpByRegionChart,
    BarEdpBySiteChart,
    BarEdpBySecteurChart,
    BarEdpByBrancheChart,
    BarAmenagementPosteByPartiesPrenantesExternesChart,
    BarAmenagementPosteByPartiesPrenantesExternesBySiteChart,
    BarAmenagementPosteByPartiesPrenantesExternesBySecteurChart,
    BarAmenagementPosteBySiteChart,
    BarDossiersByMaladieProChart,
    BarConsultationsIstByMoisChart,
    BarAmenagementPosteBySecteurChart,
    BarEdpByPartiesPrenantesExternesChart,
    BarConsultationsIstByOrigineChart,
    BarConsultationsIstByTypeVisiteChart,
    BarConsultationsIstByExamensComplementaireChart,
    BarConsultationsIstByRaisonsVenueChart,
    BarConsultationsIstByOrientationChart,
    BarConsultationsIstByActionsRealiseesChart,
    BarConsultationsIstByDouleursPhysiquesChart,
    BarConsultationsIstByJoursChart,
    BarConsultationsIstByTranchesAgeChart,
    BarConsultationsIstByPrecisionsRaisonsVenueAtChart,
    DonutConsultationsIstByPrecisionsRaisonsVenueChart,
    BarConsultationsIstByLocalisationsDouleursOnlyAtChart,
    BarConsultationsIstByLocalisationsDouleursOnlyMpAndTmsChart,
    DonutConsultationsIstBySexeChart,
    DonutConsultationsIstByTypesContratsChart,
    DonutConsultationsIstByCspChart,
    DonutConsultationsIstByTempsTravailChart,
    DonutConsultationsIstByHorairesChart,
    DonutAptitudeAvisChart,
    BTable,
    ActionsByTypeActionChart,
    TempsByTypeActionAdmChart,
    BarPartiesPrenantesExternesTypeActionPointInformelChart,
    BarPartiesPrenantesInternesTypeActionPointInformelChart,
    ActionsAdmSuiviPrecoBySecteurChart,
    DureeTravailConsultationsMdtChart,
    BarTypeVisiteConsultationsMdtChart,
    BarDossierByEdpContexteChart,
    BarDossierByEdpPrescripteurChart,
    BarEdpDossierBySecteurChart,
    BarOrigineConsultationsMdtChart,
    BarExamensCompByTypesExamenChart,
    BarRaisonVenueConsultationsMdtChart,
    BarNiveauStressConsultationsMdtChart,
    BarNiveauSatisfactionConsultationsMdtChart,
    BarTempsByThemeProjetPstChart,
    BarTempsByThemeProjetPspChart,
    BarPointNegatifConsultationsMdtChart,
    BarActionRealiseeConsultationsMdtChart,
    BarPointPositifConsultationsMdtChart,
    BarOrientationConsultationsMdtChart,
    DouleurPsychiqueConsultationsMdtChart,
    DonutConsultationsMdtByPathologiesSoinTravailChart,
    PieAmtEdpTypePosteByFamillesRisquesChart,
    BarAmenagementPosteByPartiesPrenantesInternesChart
  },
})
export default class RapportActivites extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;
  statType = EnumNumberStat;
  douleurPhysiqueType = EnumDouleurPhysiqueStat;
  douleurPsychiqueType = EnumDouleurPsychiqueStat;
  etatRa = EtatDemandeRA;
  graphs = graphList;
  searchLoading = false;
  charteLoad = false;
  loadingPPTExport = false;
  exportStarted = false;
  downloading = false;
  graphModelToExport: [] = [];

  listeColors: any[] = [];
  graphToExportSVG: any[] = [];

  loadStats = false;

  refreshIndex = 1;

  required = required;

  selected: any = null;

  loadAllGraph = false;
  isReady = false;

  getPreview = false;
  canExportPPT = this.$store.state.user.user.email == "r.lardier@sstrn.com";

  templateSelected: RapportActiviteTemplate | null = null;

  listeEntreprises: Entreprise[] = [];
  totalCountEntreprise: number = 0;

  listeRegion: Region[] = [];
  totalCountRegion: number = 0;

  listeBranche: Branche[] = [];
  totalCountBranche: number = 0;

  listeSite: Site[] = [];
  totalCountSite: number = 0;

  listeSecteur: Secteur[] = [];
  totalCountSecteur: number = 0;

  charteClone: { couleurCharteGraphiques: [] } = {
    couleurCharteGraphiques: [],
  };

  totalCountService: number = 0;
  listeTags: Service[] = [];

  savedColors: any[] = [];
  listTemplates: any[] = [];
  charteColors: any[] = [];

  model: RapportActiviteModel = JSON.parse(
    '{"entreprisesIds": [],"regionsId":[],"colors":["#1f9bd1","#1d3557","#e63946","#a8dadc","#457b9d","#1f9bd1","#1d3557","#e63946","#a8dadc","#457b9d","#1f9bd1","#1d3557","#e63946","#a8dadc","#457b9d","#1f9bd1","#1d3557","#e63946","#a8dadc","#457b9d"],"sitesId":[],"branchesId":[],"secteursId":[],"allEntreprises":false,"periodePrecedentes":false,"onlyMesDossier":false,"dateDebut":"01/01/2024","dateFin":"31/12/2024", "filters":[]}',
  );
  listStats: any[] = [];

  withoutNational = false;
  connection: signalR.HubConnection | null = null;
  currentUserId: any = null;

  waitingCount = 0;
  isWaitingForExport = false;
  isWaitingForExportByUser = false;
  tempGraphReady = false;
  downloadReady = false;
  charteSelected: CharteGraphique = {};
  isReadOnly = true;

  numberItem: boolean = false;
  entrepriseItem: boolean = false;
  comparaisonBySecteurItem: boolean = false;
  tempsByAsItem: boolean = false;
  informationsDomainesActivitesItem: boolean = false;
  informationsInterventionsItm: boolean = false;

  totalCountDemandes: number = 0;
  listeDemandes: RapportActiviteDemande[] = [];

  demandesParams = {
    pageSize: 5,
    pageNumer: 1,
  };

  demandeSelected: RapportActiviteDemandeModel | null = null;
  previousTemplate: RapportActiviteTemplate | null = null;
  previousModel: any = null;

  showDemandesStatusModal: boolean = false;
  tableDemandesColumns = [
    {
      key: "DateTraitement",
      sortable: false,
      sortKey: "DateTraitement",
      class: "text-center",
    },
    { key: "Etat", sortable: false, sortKey: "Etat", class: "text-center" },
    { key: "Actions", sortable: false },
  ];

  get tenantPreferences() {
    return this.$store.state.user.tenant_preferences;
  }

  get entrepriseSectionTitle() {
    return this.model.entreprisesIds!.length == 1
      ? "Informations relative à l'entreprise"
      : "Informations relatives aux entreprises";
  }

  get config() {
    return this.tenantPreferences.find((value: any) => {
      return value.key == "config_rapport_activite";
    });
  }

  async created() {
    await this.getCurrentUser();
    await this.loadDemandes();
    await this.loadTemplates();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(appSettings.getApiURL() + "rapport-activite-hub")
      .build();

    this.connection.on("waitingExport", (count: any) => {
      this.waitingCount = count;
    });

    this.connection.on("startExport", () => {
      if (this.getPreview) {
        this.askModifOnExport();
      }
    });

    this.connection.on("reloadDemandes", () => {
      this.loadDemandes();
      this.refreshIndex++;
    });

    this.connection.onclose(async () => {
      this.$swal({
        title: "Erreur de connexion",
        text: "Une erreur de connexion avec le serveur s'est produite",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    });

    this.$bvModal.show("listTemplates");
  }

  async getCurrentUser() {
    if (!this.currentUserId) {
      await this.$authService.getUser().then((user: any) => {
        this.currentUserId = user.profile.sub;
      });
    }
  }

  async checkConnection() {
    if (
      this.connection!.state.valueOf() != signalR.HubConnectionState.Connected
    ) {
      await this.connection!.start().catch((err: any) =>
        console.log("erreur lors de la connection avec signalR : ", err),
      );
    }
  }

  getEntrepriseName(id: string) {
    return this.demandeSelected?.infoEntrepriseRapportActiviteDemandeDto.find(
      (e: any) => e.id == id,
    )?.nom;
  }

  getRegionName(id: string) {
    return this.listeRegion.find((e) => e.id == id)?.libelle;
  }

  getSiteName(id: string) {
    return this.listeSite.find((e) => e.id == id)?.libelle;
  }

  getTagName(id: string) {
    return this.listeTags.find((s) => s.id == id)?.libelle;
  }

  getSecteurName(id: string) {
    return this.listeSecteur.find((e) => e.id == id)?.libelle;
  }

  getBrancheName(id: string) {
    return this.listeBranche.find((e) => e.id == id)?.libelle;
  }

  getTemplateName(id: string) {
    return this.listTemplates.find((t) => t.id == id).libelle;
  }

  @Watch("demandesParams", { deep: true })
  async loadDemandes() {
    await this.$http.ressifnet.rapportActivites
      .demandes(this.demandesParams.pageNumer, this.demandesParams.pageSize)
      .then((res: PaginatedList<RapportActiviteDemande>) => {
        this.totalCountDemandes = res.totalCount;
        this.listeDemandes = res.items;
        this.listeDemandes.forEach((d) => {
          d.parameters = JSON.parse(d.parameters);
        });
      });
  }

  copyParameters(demande: RapportActiviteDemande) {
    this.templateSelected = this.listTemplates.find(
      (t) => t.id == demande.template,
    );
    this.model = JSON.parse(JSON.stringify(demande.parameters));
    this.model.entreprisesIds = demande.infoEntrepriseRapportActiviteDemandeDto.map((e: any) => e.id);
    this.setChartLoaded();
  }

  askModifOnExport() {
    this.$bvModal
      .msgBoxOk(
        'Vous pouvez encore modifier vos graphiques et cliquer sur le bouton "Télecharger" lorsque vous aurez finit vos modifications',
        {
          title: "L'export est prêt",
          size: "sm",
          okOnly: true,
          okVariant: "primary",
          okTitle: "Compris",
          hideHeaderClose: false,
          centered: true,
        },
      )
      .then(async (value) => {
        this.downloadReady = true;
        this.loadTempExportPPT();
      });
  }

  async transformRefsToListCharts() {
    setTimeout(() => {
      for (let i = 0; i < Object.keys(this.$refs).length; i++) {
        let refObject = this.$refs[Object.keys(this.$refs)[i]] as any;
        let title =
          refObject && refObject.title ? refObject.title : "todo title";
        let obj: {} = {
          ref: (this.$refs[Object.keys(this.$refs)[i]] as any).graphReference,
          title: title,
        };
        this.listStats.push(obj);
      }
    }, 500);
  }

  getRandomColor(): string {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  setChartLoaded() {
    const charte = this.templateSelected!.charteGraphique;
    this.charteClone = JSON.parse(JSON.stringify(charte));
    this.charteSelected = JSON.parse(JSON.stringify(charte));
    this.charteClone.couleurCharteGraphiques?.forEach((e: any) => {
      this.charteColors.push(e.code);
    });
    while (this.charteColors.length < 15) {
      var color = this.getRandomColor();
      this.charteColors.push(color);
    }
    this.charteLoad = true;
  }

  goToRef(refname: string) {
    setTimeout(() => {
      let formCard = this.$refs[refname] as any;
      if (formCard) formCard.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }

  async loadTemplates() {
    await this.$http.ressifnet.rapportActiviteTemplates.paginatedList().then(
      (response: any) => {
        this.listTemplates = response["items"];
      },
      (error: any) => {
        console.log(error);
      },
    );
  }

  async restartExportPPT(demande: RapportActiviteDemande) {
    if (this.templateSelected)
      this.previousTemplate = JSON.parse(JSON.stringify(this.templateSelected));
    else await this.loadTemplates();

    if (this.model) {
      this.previousModel = JSON.parse(JSON.stringify(this.model));
    }

    this.templateSelected = this.listTemplates.find(
      (template) => template.id == demande!.template,
    );
    this.setChartLoaded();

    this.model = JSON.parse(JSON.stringify(demande!.parameters));

    this.getPreview = false;
    this.isWaitingForExport = true;

    await this.checkConnection();
    await this.connection!.invoke("restartExport", demande.id).catch(
      (err: any) => console.log("erreur signalr : ", err),
    );

    this.exportStarted = true;
    this.loadDirectExportPPT();
  }

  async askExportPPT() {
    this.$bvModal
      .msgBoxConfirm(
        "Voulez-vous lancer directement l'export lorsqu'il sera prêt ? Ou souhaitez vous modifier les graphiques lorsqu'ils seront chargés",
        {
          title: "Export des données",
          size: "lg",
          okVariant: "primary",
          okTitle: "Lancer directement l'export",
          cancelTitle: "Afficher une prévisualisation",
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
        },
      )
      .then(async (value) => {
        this.downloadReady = true;
        if (value == true) {
          // Lancer l'export sans pause
          this.getPreview = false;
          this.isWaitingForExport = true;
          this.loadDirectExportPPT();
          await this.checkConnection();

          await this.connection!.invoke("newExport", {
            templateId: this.templateSelected?.id,
            parameters: JSON.stringify(this.model),
            createdBy: this.currentUserId,
          }).catch((err: any) => console.log("erreur signalr : ", err));
          this.exportStarted = true;
        } else if (value == false) {
          // Lancer la preview
          this.exportStarted = false;
          this.getPreview = true;
          this.loadTempExportPPT();
          await this.checkConnection();
          await this.connection!.invoke("newExport", {
            templateId: this.templateSelected?.id,
            parameters: JSON.stringify(this.model),
            createdBy: this.currentUserId,
          }).catch((err: any) => console.log("erreur signalr : ", err));
          this.exportStarted = true;
        } else {
        }
      });
  }

  openCollapseItem(element: any) {
    switch (element.id) {
      case "numberItem":
        (this.$refs["numberItem"] as any).visible = true;
        break;
      case "entrepriseItem":
        this.entrepriseItem = true;
        break;
      case "comparaisonBySecteurItem":
        this.comparaisonBySecteurItem = true;
        break;
      case "tempsByAsItem":
        this.tempsByAsItem = true;
        break;
      case "informationsDomainesActivitesItem":
        (this.$refs["informationsDomainesActivitesItem"] as any).visible = true;
        break;
      case "informationsInterventionsItm":
        this.informationsInterventionsItm = true;
        break;
      default:
        break;
    }
  }

  async loadDirectExportPPT() {
    // this.$bvToast.show('toast-export')
    this.loadAllGraph = true;
    this.transformRefsToListCharts();

    var collapseItems: object[] = [];

    // Délai avant l'affichage des div
    setTimeout(() => {
      this.templateSelected?.configs.forEach(
        async (c: RapportActiviteConfig) => {
          c.graphRef = c.graphRef.toLowerCase();

          var graphBaseMetrics = this.graphs.find((x) => x.ref.toLowerCase() == c.graphRef.toLowerCase());

          var legend: any = {
            show: true,
            position: c.legendPosition,
            fontSize: "17px",
          };

          if (
            (graphBaseMetrics?.graphType == graphType.PIE ||
              graphBaseMetrics?.graphType == graphType.DONUT) &&
            !graphBaseMetrics?.legendFixed
          ) {
            legend.formatter = function (val: any, opts: any) {
              //  let total = 0;
              //  for (let x of opts.w.config.series) { total += x; }
              let selected = opts.w.config.series[opts.seriesIndex];
              return (
                opts.w.config.labels[opts.seriesIndex] + " (" + selected + ")"
              );
            };
          }

          if (c.legendPosition == "bottom" || c.legendPosition == "top") {
            if (graphBaseMetrics?.legendHeight)
              legend.height = graphBaseMetrics!.legendHeight;
            legend.width = undefined;

            if (graphBaseMetrics?.legendWidthWhenTopOrBottom) {
              legend.width = graphBaseMetrics?.legendWidthWhenTopOrBottom;
              legend.offsetX = graphBaseMetrics?.legendOffsetWhenTopOrBottom;
            }
          } else if (
            c.legendPosition == "right" ||
            c.legendPosition == "left"
          ) {
            if (graphBaseMetrics?.legendWidth)
              legend.width = graphBaseMetrics!.legendWidth;
            legend.offsetX = 0;
          }

          console.log("begin", c.graphRef);
          if (
            (this.$refs[c.graphRef] as any) &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef] &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].$refs[c.graphRef]
          ) {
            (
              (this.$refs[c.graphRef] as any).$refs[c.graphRef].$refs[
                c.graphRef
              ] as ApexCharts
            ).updateOptions({ legend }, false, false, false);
          }
          console.log("end", c.graphRef);

          try {
            await (this.$refs[c.graphRef] as any).loadTrueData(c.graphRef, c);
          } catch (err) {
            c.error = true;
            await this.checkConnection();
            await this.connection!.invoke("OnError").catch((err: any) =>
              console.log("erreur signalr : ", err),
            );
          }
        },
      );
    }, 4000);

    var intervalId = setInterval(() => {
      var graphsNotLoaded: string[] = [];

      this.templateSelected?.configs.forEach((c: RapportActiviteConfig) => {
        c.graphReady =
          // c'est pas un nombre, donc un SVG
          (this.$refs &&
            (this.$refs[c.graphRef] as any) &&
            !(this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].locked == false &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].loading ==
              false) ||
          // c'est un nombre
          ((this.$refs[c.graphRef] as any) &&
            !(this.$refs[c.graphRef] as any).hasOwnProperty("isPercentStat") &&
            (this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            (this.$refs[c.graphRef] as any).locked == false &&
            (this.$refs[c.graphRef] as any).loading == false) ||
          ((this.$refs[c.graphRef] as any) &&
            (this.$refs[c.graphRef] as any).hasOwnProperty("isPercentStat") &&
            (this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].locked == false &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].loading == false);

        // Gestion si erreur
        if (c.error) {
          graphsNotLoaded.push(c.graphRef);
          c.graphReady = true;
        }
      });
      let allChartReadys = this.templateSelected?.configs.every(
        (x: any) => x.graphReady || x.error,
      );

      // Envoie un message d'erreur pour les graphiques avec une erreur de chargement
      if (graphsNotLoaded.length > 0) {
        const errorMessage =
          "Erreur de chargement pour les graphiques suivants : " +
          graphsNotLoaded.join(", ");
        this.$swal({
          title: "Problème de chargement d'un ou plusieurs graphique(s)",
          text: errorMessage,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }

      // ils sont tous prêts
      if (allChartReadys) {
        clearInterval(intervalId);
        setTimeout(() => {
          this.downloadPPT();
        }, 10000);
      }
    }, 4000);
  }

  async loadTempExportPPT() {
    this.loadAllGraph = true;
    this.transformRefsToListCharts();

    // Délai avant l'affichage des div
    setTimeout(() => {
      this.templateSelected?.configs.forEach(async (c: any) => {
        try {
          await (this.$refs[c.graphRef] as any).loadTrueData(c.graphRef);
        } catch (err) {
          c.error = true;
          await this.checkConnection();
          await this.connection!.invoke("OnError").catch((err: any) =>
            console.log("erreur signalr : ", err),
          );
        }
      });
    }, 2500);

    var intervalId = setInterval(() => {
      var graphsNotLoaded: string[] = [];

      this.templateSelected?.configs.forEach((c: any) => {
        c.graphReady =
          // c'est pas un nombre, donc un SVG
          (this.$refs &&
            (this.$refs[c.graphRef] as any) &&
            !(this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].locked == false &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].loading ==
              false) ||
          // c'est un nombre
          ((this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            !(this.$refs[c.graphRef] as any).hasOwnProperty("isPercentStat") &&
            (this.$refs[c.graphRef] as any).locked == false &&
            (this.$refs[c.graphRef] as any).loading == false) ||
          ((this.$refs[c.graphRef] as any).hasOwnProperty("numberToShow") &&
            (this.$refs[c.graphRef] as any).hasOwnProperty("isPercentStat") &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].locked == false &&
            (this.$refs[c.graphRef] as any).$refs[c.graphRef].loading == false);

        // Gestion si erreur
        if (c.error) {
          graphsNotLoaded.push(c.graphRef);
          c.graphReady = true;
        }
      });

      // Vérification si les graphiques sont prêts
      let allChartReadys = this.templateSelected?.configs.every(
        (x: any) => x.graphReady || x.error,
      );

      // Envoie un message d'erreur pour les graphiques avec une erreur de chargement
      if (graphsNotLoaded.length > 0) {
        const errorMessage =
          "Erreur de chargement pour les graphiques suivants : " +
          graphsNotLoaded.join(", ");
        this.$swal({
          title: "Problème de chargement d'un ou plusieurs graphique(s)",
          text: errorMessage,
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }

      // ils sont tous prêts
      if (allChartReadys) {
        clearInterval(intervalId);
        this.tempGraphReady = true;
        this.exportStarted = false;

        this.$bvModal
          .msgBoxOk(
            "Votre export est prêt, vous pouvez modifier vos graphiques puis cliquer sur télécharger en bas à droite du panneau des filtres",
            {
              title: "Export des données",
              size: "lg",
              okVariant: "primary",
              okTitle: "D'accord, merci",
              hideHeaderClose: true,
              centered: true,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
            },
          )
          .then(async (value) => {
            this.downloadReady = true;
            clearInterval(intervalId);
          });
      } else {
        this.tempGraphReady = false;
      }
    }, 5000);
  }

  async downloadPPT() {
    this.refreshIndex++;

    this.$swal({
      title: "Télécharger le rapport",
      html: 'Votre rapport est prêt. Cliquez sur "Lancer le téléchargement" pour le télécharger et <span class="font-weight-bold">veillez à ne pas changer d\'onglet durant cette courte période.</span>',
      icon: "warning",
      customClass: {
        confirmButton: "btn btn-warning mr-1",
        denyButton: "btn btn-secondary ml-1",
      },
      buttonsStyling: false,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Lancer le téléchargement",
      denyButtonText: `Annuler`,
      backdrop: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.downloadReady = true;
        let promises: Promise<void>[] =
          this.templateSelected?.configs.map(async (c: any) => {
            let graphRef = (this.$refs[c.graphRef] as any)?.$refs[c.graphRef];

            try {
              console.log("begin with", graphRef);

              if (graphRef?.usePng) {
                c.png = await graphRef?.getPng(c);

                console.log(c.png);
              } else if (graphRef?.isPercentStat) {
              } else {
                c.svg = await graphRef?.getSvg(c);

                console.log(c.svg);
              }

              console.log(c.graphRef, this.$refs[c.graphRef] as any);
              c.count =
                (this.$refs[c.graphRef] as any).numberToShow ??
                (this.$refs[c.graphRef] as any).$refs[c.graphRef]
                  ?.pourcentageToShow;
              console.log("count", c.graphRef, c.count);
            } catch (ex) {
              console.log("err for", graphRef, c);
              console.log(ex);
            }
          }) ?? [];
        await Promise.all(promises);

        setTimeout(async () => {
          console.log("Export started!");
          var data: GeneratePowerpointModel = {
            configs: this.templateSelected!.configs,
            templateId: this.templateSelected!.id,
          };
          this.exportStarted = false;
          this.downloading = true;
          await this.$http.ressifnet.rapportActivites.exportPPT(data).then(
            (response: any) => {
              this.downloading = false;
              if (this.previousModel)
                this.model = JSON.parse(JSON.stringify(this.previousModel));
              if (this.previousTemplate)
                this.templateSelected = JSON.parse(
                  JSON.stringify(this.previousTemplate),
                );

              var file = new Blob([response], {
                type: "application/octet-binary;charset=utf-8",
              });
              var a = document.createElement("a"),
                url = URL.createObjectURL(file);
              a.href = url;

              const [jour, mois, annee] = this.model.dateDebut!
                .split("/")
                .map(Number);
              const [jourF, moisF, anneeF] = this.model.dateFin!
                .split("/")
                .map(Number);

              let entrepriseNames = this.listeEntreprises
                .filter((x: any) => this.model.entreprisesIds!.includes(x.id))
                .map((x: any) => x.nom.replace(/\s+/g, "-"))
                .join("_")
                .substring(0, 20);

              let fileName =
                "export" +
                "_" +
                jour.toString().padStart(2, "0") +
                mois.toString().padStart(2, "0") +
                annee +
                "_" +
                jourF.toString().padStart(2, "0") +
                moisF.toString().padStart(2, "0") +
                anneeF +
                "_" +
                entrepriseNames +
                ".pptx";

              a.download = fileName;

              document.body.appendChild(a);
              a.click();

              this.loadingPPTExport = false;
              this.isWaitingForExport = false;
              this.waitingCount = 0;
              this.refreshIndex++;

              this.previousModel = null;
              this.previousTemplate = null;
              this.connection!.invoke("exportSuccessful");
            },
            (error: any) => {
              console.log(error);
            },
          );
        }, 3000);
      } else {
        this.loadingPPTExport = false;
        this.isWaitingForExport = false;
        this.waitingCount = 0;
        this.refreshIndex++;

        this.previousModel = null;
        this.previousTemplate = null;
        this.connection!.invoke("exportSuccessful");

        this.exportStarted = false;
      }
    });
  }

  async loadAllGraphiques() {
    this.loadAllGraph = true;
    this.transformRefsToListCharts();
    this.isReadOnly = false;
    // this.listStats.forEach((e: any) => {
    //   console.log(this.$refs)
    //   if ((this.$refs[e.ref] as any)) {
    //     (this.$refs[e.ref] as any).loadStatistiques(this.charteColors);
    //   }
    // });
  }

  async searchEntreprises(params: any) {
    if (params?.reset) {
      this.listeEntreprises = this.listeEntreprises.filter((e) =>
        this.model.entreprisesIds?.some((id: any) => id == e.id),
      );
      this.totalCountEntreprise = this.listeEntreprises.length;
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber,
        params?.pageSize,
        params?.search,
        this.withoutNational,
      )
      .then((res: PaginatedList<Entreprise>) => {
        this.totalCountEntreprise = res.totalCount;
        this.listeEntreprises = this.listeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.listeEntreprises!.some((lsi) => lsi.id == ri.id),
          ),
        );
      });
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      if (Array.isArray(this.model.regionsId) && this.model.regionsId.length > 0) {
        this.listeRegion = this.listeRegion?.filter(
          (e) => this.model.regionsId!.includes(e.id)
        );
      }
      this.totalCountRegion = this.listeRegion.length;
    }

    var entrepriseId = this.model.entreprisesIds![0];
    await this.$http.ressifnet.regions
      .paginatedList(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Region>) => {
        this.totalCountRegion = res.totalCount;
        this.listeRegion = this.listeRegion!.concat(
          res.items.filter(
            (ri) => !this.listeRegion!.some((lsi: any) => lsi.id == ri.id),
          ),
        );
      });
  }

  async searchBranches(params: any) {
    if (!params || params?.reset) {
      if (Array.isArray(this.model.branchesId!) && this.model.branchesId!.length > 0) {
        this.listeBranche = this.listeBranche?.filter(
          (e) => this.model.branchesId!.includes(e.id)
        );
      }
      this.totalCountBranche = this.listeBranche.length;
    }

    var entrepriseId = this.model.entreprisesIds![0];
    await this.$http.ressifnet.branches
      .paginatedList(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Branche>) => {
        this.totalCountBranche = res.totalCount;
        this.listeBranche = this.listeBranche!.concat(
          res.items.filter(
            (ri) => !this.listeBranche!.some((lsi: any) => lsi.id == ri.id),
          ),
        );
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      if (Array.isArray(this.model.sitesId!) && this.model.sitesId!.length > 0) {
        this.listeSite = this.listeSite?.filter(
          (e) => this.model.sitesId!.includes(e.id)
        );
      }
      this.totalCountSite = this.listeSite.length;
    }

    var entrepriseId = this.model.entreprisesIds![0];
    await this.$http.ressifnet.sites
      .paginatedList(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount;
        this.listeSite = this.listeSite!.concat(
          res.items.filter(
            (ri) => !this.listeSite!.some((lsi: any) => lsi.id == ri.id),
          ),
        );
      });
  }

  async searchSecteurs(params: any) {
    if (!params || params?.reset) {
      if (Array.isArray(this.model.secteursId!) && this.model.secteursId!.length > 0) {
        this.listeSecteur = this.listeSecteur?.filter(
          (e) => this.model.secteursId!.includes(e.id)
        );
      }
      this.totalCountSecteur = this.listeSecteur.length;
    }

    var entrepriseId = this.model.entreprisesIds![0];
    await this.$http.ressifnet.secteurs
      .paginatedList(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSecteur = res.totalCount;
        this.listeSecteur = this.listeSecteur!.concat(
          res.items.filter(
            (ri) => !this.listeSecteur!.some((lsi: any) => lsi.id == ri.id),
          ),
        );
        this.listeSecteur.map((x) =>
          this.listeSecteur.forEach(
            (x) => (x.completeLabel = x.libelle + " (" + x.siteLibelle + ")"),
          ),
        );
      });
  }

  async searchTags(params: any) {
    if (!params || params?.reset) {
      this.listeTags = this.listeTags?.filter((e) =>
        this.model.tagsId?.some((id: any) => id == e.id),
      );
      this.totalCountService = this.listeTags.length;
    }

    var entrepriseId = this.model.entreprisesIds![0];

    await this.$http.ressifnet.tags
      .paginatedList(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<Service>) => {
        this.totalCountService = res.totalCount;
        this.listeTags = this.listeTags!.concat(
          res.items.filter(
            (ri) => !this.listeTags!.some((lsi) => lsi.id == ri.id),
          ),
        );
      });
  }

  @Watch("withoutNational")
  async selectWithoutNational() {
    if (this.model.allEntreprises) {
      this.listeEntreprises = [{ nom: "Chargement...", id: "loading" }];
      this.model.entreprisesIds = ["loading"];

      await this.searchEntreprises({
        pageNumber: 1,
        pageSize: 2000,
        reset: true,
      });
      this.listeEntreprises = this.listeEntreprises.filter(
        (x) => x.id != "loading",
      );
      this.model.entreprisesIds = this.listeEntreprises
        .filter((x) => x.id != "loading")
        .map((x) => x.id);
    }
  }

  @Watch("model.allEntreprises")
  async selectAllEntreprises() {
    if (!this.previousModel && this.model.allEntreprises == false) return;
    this.listeEntreprises = this.model.allEntreprises
      ? [{ nom: "Chargement...", id: "loading" }]
      : [];
    this.model.entreprisesIds = this.model.allEntreprises ? ["loading"] : [];

    var pageSize = this.model.allEntreprises ? 2000 : 10;
    this.withoutNational = !this.model.allEntreprises
      ? false
      : this.withoutNational;
    await this.searchEntreprises({
      pageNumber: 1,
      pageSize: pageSize,
      reset: true,
    });

    this.listeEntreprises = this.listeEntreprises.filter(
      (x) => x.id != "loading",
    );
    this.model.entreprisesIds = this.model.allEntreprises
      ? this.listeEntreprises.map((x) => x.id)
      : [];

    this.onClearBranche();
    this.onClearSite();
    this.onClearRegion();
    this.onClearSecteur();
    this.onClearService();
  }

  async selectEntreprise() {
    if (this.model.entreprisesIds?.length != 1) {
      this.onClearBranche();
      this.onClearSite();
      this.onClearRegion();
      this.onClearSecteur();
      this.onClearService();
    }
  }

  onClearBranche() {
    this.model.branchesId = [];
    this.listeBranche = [];
    this.totalCountBranche = 0;
  }

  onClearSite() {
    this.model.sitesId = [];
    this.listeSite = [];
    this.totalCountSite = 0;
  }

  onClearSecteur() {
    this.model.secteursId = [];
    this.listeSecteur = [];
    this.totalCountSecteur = 0;
  }

  onClearRegion() {
    this.model.regionsId = [];
    this.listeRegion = [];
    this.totalCountRegion = 0;
  }

  onClearService() {
    this.model.tagsId = [];
    this.listeTags = [];
    this.totalCountService = 0;
  }

  reloadPage() {
    location.reload();
  }
}
